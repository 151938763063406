* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  /* font-family: 'Hind', sans-serif; */
  /* color: #FFF7F7; */
  /* background: green; */
  color: #00345C;
  font-family: Barlow-Regular, sans-serif;
}
/* fonts */
/* 
Barlow-Regular
Barlow-Medium
Barlow-SemiBold
Barlow-Bold
Barlow-ExtraBold
Barlow-SemiCondensedExtraBold
Montserrat-Bold
Gilroy-Bold
Gilroy-ExtraBold
Gilroy-Medium
Gilroy-Regular
Gilroy-SemiBold
*/
/* body {
  width: 1200px; 
  margin: 0 0;
} */
.navigation-section .navigation-logo {
  /* height: 30px; */
  margin: 10px 20px;  
}
.logo-produce {
  height: 30px;
}
.logo-pnipa {
  height: 30px;
}
.logo-banco {
  height: 30px;
}
.logo-pueblo {
  height: 35px;
}
.logo-bicentenario {
  height: 45px;
}

.navigation-links ul {
  list-style: none;
  text-align: center;
  padding: 0;
  margin: 0;
}
.navigation-links li {
  background: #FF5A64;
  margin: 0px 1px;
  height: 32px
}

.navigation-links a {
  text-decoration: none;
  font-family: Gilroy-Bold;
  font-size: 11px;
  color: white;
  padding: 10px 0px;
}
.navigation-links a div {
  text-decoration: none;
  font-family: Gilroy-Bold;
  font-size: 11px;
  line-height: 12px;
  overflow: hidden;
  color: white;
  /* padding: 10px 0px; */
}

/* Sections */
.section {
  padding-top: 60px;
  /* padding-bottom: 60px; */
}
.section p {
  font-size: 18px;
  line-height: 22px;
  font-family: Barlow-Medium;
  text-align: justify;
}

h2 {
  font-family: Montserrat-Bold;
  font-size: 44px;
  position: relative;
  margin-bottom: 20px;
}
h2:after {
  content: '';
  position: absolute;
  width: 128px;
  height: 10px;
  bottom: -10px;
  left: 0;
  background: #FFF7F7;
}

/* Slider */
.carousel-indicators { 
  visibility: hidden; 
}
.slider-slide-first {
  height: 380px;
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  background-size: cover;
  background-position: center -300px;
}
.slider-slide-first .slider-slogan {
  height: 150px;
}
.slider-slide-first .slider-title {
  font-family: Gilroy-Bold;
  font-size: 91px;
  line-height: 94px;
  color: white;
  /* margin: 30px 0px; */
}
.slider-slide-first .slider-text-red {
  font-family: Gilroy-Bold;
  font-size: 33px;
  color: #FF5A64;
  border: 1px solid white;
  background-color:rgba(0, 0, 0, 0.2);
  padding: 0px 12px;
  /* margin: 30px 0px; */
}
.slider-slide-first .slider-text-white {
  font-family: Gilroy-Bold;
  font-size: 20px;
  color: white;
  padding: 0px 12px;
}

.slider-slide-second {
  height: 380px;
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  background-size: cover;
  opacity: .9;
  background-position: center -150px;
}
.slider-slide-second .slider-slogan {
  height: 70px;
  margin-top: 40px;
}
.slider-slide-second .slider-title {
  font-family: Gilroy-Bold;
  font-size: 32px;
  color: white;
  margin: 30px 0px;
}
.slider-icon {
  height: 110px;
}


/* Intro */
.intro-section {
  background: white;
  padding-top: 60px;
  padding-bottom: 60px;
  text-align: justify;
}

.intro-section span{
  font-family: Barlow-Bold;
  color: #FF5A64;

}
.intro-section p {
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 8px;
}
.intro-p {
  font-family: Barlow-Medium;
}
.img-container {
  position: relative;
  padding-right: 10px;
  /* height: 390px; */

}
.intro-img-video {
  width: 100%;
  object-fit: contain;
  border-radius: 14px;
}
.intro-icon-play {
  position: absolute;
  bottom: 5%;
  left: 50%;
  margin-left: -20px;
  height: 30px;
  /* background: green; */
  z-index: 1;

}
.intro-btn {
  font-family: Gilroy-SemiBold;
  color: white;
  font-size: 18px;
  border: none;
  border-radius: 18px;
  background: #FF5A64;
  margin-left: 10px;
  margin-bottom: 8px;
  padding-left: 8px;
  padding-right: 8px;
}
.btn-more{
  max-width: 16px;
  margin-right: 5px;

  width: 100%;
  object-fit: contain;
  border-radius: 14px;
}

/* Actividades */
.act-section {
  background: #FF5A64;
}
.act-section * {
  color: white;
}
.act-icons {
  margin-top: 40px;
  margin-bottom: 60px;
}
.act-icon {
  background: #00345C;
  border-radius: 50%;
  height: 200px;
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  text-align: center;
  padding: 40px 20px 10px;
}
.act-icon img{
  height: 60px;
  width: 60px;
  margin-bottom: 5px;
}
.act-icon div {
  font-family: Gilroy-Medium;
  font-size: 17px;
  line-height: 20px;
}
.act-icon span{
  font-family: Gilroy-Bold;
}
.act-icon .act-logo{
  margin-top: 5px;
  height: 25px;
  width: auto;
}
.act-bottom {
  background: #ff6b74;
  padding-top: 40px;
  padding-bottom: 40px;
  z-index: 10;
}
.act-bottom div{
  color: white;
}
.act-bottom-plus {
  text-align: center;
  font-family: Gilroy-Bold;
  font-size: 60px;
  line-height: 40px;
}
.act-bottom-de {
  text-align: center;
  font-family: Gilroy-Bold;
  font-size: 40px;
  line-height: 30px;
}
.act-bottom-number {
  font-family: Gilroy-ExtraBold;
  font-size: 82px;
  line-height: 82px;
  margin-left: 5px;
}
.act-bottom-text {
  font-family: Gilroy-Bold;
  font-size: 24px;
  line-height: 28px;
  margin-left: 10px;
}

/* Cadenas de valor */
.value-section h2 {
  color: #FF5A64;
}
.value-section h2:after {
  background: #FF5A64;
}
.value-section img{
  height: 130px;
}

.value-items {
  /* margin-top: 40px; */
  margin-bottom: 20px;
}

.value-item {
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
}
.value-item-text {
  position: relative;
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  
}
.value-item-text:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: -15px;
  left: 0;
  background: #FF5A64;
}
.last:after {
  background: transparent;
}
.value-name {
  color: #FF5A64;
  font-family: Gilroy-Medium;
  font-size: 35px;
  line-height: 35px;
}
.value-slash img {
  margin-right: 15px;
  height: 80px;
}
.value-plus {
  text-align: center;
  font-family: Gilroy-Bold;
  font-size: 60px;
  line-height: 40px;
}
.value-de {
  text-align: center;
  font-family: Gilroy-Bold;
  font-size: 40px;
  line-height: 30px;
}
.value-number {
  font-family: Gilroy-ExtraBold;
  font-size: 82px;
  line-height: 82px;
  margin-left: 5px;
}
.value-text {
  font-family: Gilroy-Bold;
  font-size: 24px;
  line-height: 28px;
  margin-left: 10px;
}

.value-bottom {
  background: #FF6a73;
  padding-top: 40px;
  padding-bottom: 40px;
}

.value-bottom div{
  color: white;
}
.value-bottom-number {
  font-family: Gilroy-ExtraBold;
  font-size: 108px;
  line-height: 82px;
  margin-left: 5px;
}
.value-bottom-text {
  font-family: Gilroy-Bold;
  font-size: 24px;
  line-height: 28px;
  margin-left: 10px;
}

/* Eventos */
.event-section {
  background: #00345C;
  padding-bottom: 60px;
}
.event-section * {
  color: white;
}
.event-section h2 {
  color: #FF5A64;
}
.event-section h2:after {
  background:  #FF5A64;
}
.event-items {
  margin-top: 50px;
}
.event-item {
  height: 290px;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.event-item-bg-lo {
  background: #F19A00;
}
.event-item-bg-lb {
  background: #00C4FF;
}
.event-item-bg-lg {
  background: #00B7A3;
}
.event-item-bg-do {
  background: #F37032;
}
.event-item-bg-dg {
  background: #369A71;
}
.event-item-bg-db {
  background: #004BA5;
}

.event-logo {
  height: 38px;
  margin-top: 30px;
}

.event-name {
  font-family: Gilroy-Bold;
  font-size: 38px;
}
.event-name-coming {
  font-family: Gilroy-Bold;
  font-size: 38px;
  flex-grow: 1;
}
.event-name-medium {
  font-size: 24px;
  /* line-height: 28px; */
  margin: 10px auto;
}
.event-name-short {
  font-size: 22px;
  line-height: 22px;
  margin: 10px auto;
}

.event-icon {
  height: 30px;
  margin-bottom: 5px;
}
.event-place {
  display: flex;
  flex-direction: column;
  
}
.event-city {
  font-family: Gilroy-Bold;
  font-size: 20px;
  line-height: 20px;
}
.event-date {
  display: flex;
  flex-direction: column;
  border-left: 1px solid white;
  
}
.event-day {
  font-family: Gilroy-Bold;
  font-size: 52px;
  line-height: 52px;
}
.event-month {
  font-family: Gilroy-Bold;
  font-size: 20px;
  line-height: 20px;
}

.event-btn-register {
  font-family: Barlow-SemiBold;
  font-size: 13px;
  line-height: 13px;
  border: 1.5px solid white;
  border-radius: 5px;
  background: transparent;
  text-align: left;
  padding: 5px;
}
.btn-register-icon {
  height: 20px;
  margin-right: 5px;
}
.event-footer {
  font-size: 13px;
  margin-top: 10px;
  margin-bottom: 10px;
}

/* Inscripción */
.reg-section h2 {
  color: #FF5A64;
}
.reg-section h2:after {
  background: #FF5A64;
  bottom: -10px;
}
.reg-section h3 {
  color: #FF5A64;
  font-family: Montserrat-Bold;
  font-size: 31px;
  margin-top: 40px;
  margin-bottom: 20px;
}
.reg-section label {
  color: #FF5A64;
  font-family: Gilroy-Bold;
  font-size: 16px;
  margin-bottom: 5px;
}
.reg-section input {
  color: #00345C;
  background: #F2F2F2;
  border: none;
  font-family: Gilroy-Bold;
  font-size: 14px;
  padding: 10px 20px;
  margin-bottom: 10px;
}
.reg-section input:focus {
  outline: none;
}
.reg-section textarea {
  color: #00345C;
  background: #F2F2F2;
  border: none;
  font-family: Gilroy-Bold;
  font-size: 14px;
  padding: 10px 20px;
  margin-bottom: 10px;
  resize: none;
}
.reg-section textarea:focus {
  outline: none;
}
.reg-section .reg-btn {
  color: white;
  font-family: Gilroy-SemiBold;
  font-size: 16px;
  background: #FF5A64;
  border-radius: 8px;
  margin-bottom: 20px;
  /* margin: 0px auto; */
}

/* Timer */
.timer-hidden {
  position: absolute;
  visibility: hidden;
}
.timer-section {
  background: #FF5A64;
  padding: 10px 0px;
}
.timer-card {
  width: 150px;
  height: 120px;
  padding: 20px;
  border: 1px solid #00345C;
  border-radius: 20px;
  margin: 20px 5px;
  background: #FF6A73;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
}
.timer-number {
  color: white;
  font-family: Gilroy-Regular;
  font-size: 54px;
  line-height: 54px;
}
.timer-text {
  color: white;
  font-family: Gilroy-Medium;
  font-size: 11px;
}
.timer-colon {
  color: #00345C;
  font-family: Gilroy-Regular;
  font-size: 36px;
  line-height: 54px;
}

/* Footer */
.footer-section {
  background: #00345C;
}
.footer-section * {
  color: white;
  font-family: Gilroy-Regular;
}
.footer-slogan {
  height: 140px;
}
.footer-contact {
  padding: 0 40px;
  border-left: 1px solid white;
}
.footer-section h3 {
  color: white;
  font-family: Gilroy-Bold;
  font-size: 28px;
  position: relative;
  margin-bottom: 25px;
}
.footer-section h3:after {
  content: '';
  position: absolute;
  width: 90px;
  height: 8px;
  bottom: -10px;
  left: 0;
  background: #FFF7F7;
}
.footer-section p {
  color: white;
  font-family: Gilroy-Regular;
  font-size: 18px;
  margin-bottom: 5px;
}
.footer-section .footer-email {
  font-family: Gilroy-Bold;
  font-size: 18px;
  background: #FF5A64;
  padding: 2px 5px;
}
.footer-sn-icon {
  height: 40px;
  filter: brightness(0) invert(1);
  margin: 5px;  
}
.footer-links-text {
  font-family: Gilroy-Bold;
  font-size: 21px;
}
.footer-logo {
  /* height: 30px; */
  filter: brightness(0) invert(1);
  margin: 10px 20px;  
}
.footer-copyright  {
  font-family: Gilroy-Medium;
  color: #00345C;
  font-size: 16px;
  padding: 10px 0;
  opacity: .9;
  background: white;
}