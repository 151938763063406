@font-face {
  font-family: "Barlow-Bold";
  src: local("Barlow-Bold"),
    url("./fonts/Barlow-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Barlow-ExtraBold";
  src: local("Barlow-ExtraBold"),
    url("./fonts/Barlow-ExtraBold.ttf") format("truetype");
}
@font-face {
  font-family: "Barlow-Medium";
  src: local("Barlow-Medium"),
    url("./fonts/Barlow-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Barlow-Regular";
  src: local("Barlow-Regular"),
    url("./fonts/Barlow-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Barlow-SemiBold";
  src: local("Barlow-SemiBold"),
    url("./fonts/Barlow-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "BarlowSemiCondensed-ExtraBold";
  src: local("BarlowSemiCondensed-ExtraBold"),
    url("./fonts/BarlowSemiCondensed-ExtraBold.ttf") format("opentype");
}
@font-face {
  font-family: "Gilroy-Bold";
  src: local("Gilroy-Bold"),
    url("./fonts/Gilroy-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Gilroy-ExtraBold";
  src: local("Gilroy-ExtraBold"),
    url("./fonts/Gilroy-ExtraBold.ttf") format("truetype");
}
@font-face {
  font-family: "Gilroy-Medium";
  src: local("Gilroy-Medium"),
    url("./fonts/Gilroy-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Gilroy-Regular";
  src: local("Gilroy-Regular"),
    url("./fonts/Gilroy-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Gilroy-SemiBold";
  src: local("Gilroy-SemiBold"),
    url("./fonts/Gilroy-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat-Bold";
  src: local("Montserrat-Bold"),
    url("./fonts/Montserrat-Bold.ttf") format("opentype");
}